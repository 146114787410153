<template>
  <div>
    <b-table-lite
      v-if="animal.id != null"
      :fields="fields"
      :items="allIngredientsSearch"
    >
      <template #cell(selected)="{ item }">
        <div class="d-flex">
          <b-form-checkbox
            :key="item.id + '-' + item.selected"
            :checked="item.selected == 1"
            @change.native="changeSelected(item)"
          />
        </div>
      </template>
    </b-table-lite>
  </div>
</template>

<script>
import { BTableLite, BFormCheckbox } from "bootstrap-vue";
import { mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: { BTableLite, BFormCheckbox },

  data() {
    return {
      allIngredientsSearch: [],
      fields: [
        {
          key: "selected",
          label: this.$t("selected"),
          sortable: true,
          width: "100px",
        },

        {
          key: "groupname",
          label: this.$t("groupName"),
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      animal: (state) => state.animalsModule.selectedAnimal || {},
    }),
  },
  watch: {
    animal: {
      async handler() {
        if (this.animal.id != null) await this.fetchAnimalGroupsforSelection();
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.animal.id != null) await this.fetchAnimalGroupsforSelection();
  },
  methods: {
    async fetchAnimalGroupsforSelection() {
      this.allIngredientsSearch = await this.$store.dispatch(
        "animalsModule/fetchAnimalGroupsforSelection",
        this.animal.id
      );
    },
    changeSelected(item) {
      item.selected = item.selected == 1 ? 0 : 1;
      this.$store.dispatch("animalsModule/updateAnimalsGroups", {
        id: this.animal.id,
        data: this.allIngredientsSearch
          .filter((item) => item.selected == 1)
          .map((item) => item.id)
          .join(","),
      });
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: this.$t("successful"),
          icon: "CheckIcon",
          variant: "success",
          text: this.$t("updated", { type: this.$t("animalGroups") }),
        },
      });
    },
  },
};
</script>

<style></style>
